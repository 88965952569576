.content {
  padding: 32px !important;
  gap: 24px !important;
  position: absolute;
  left: 0;
  top: 57px;
  background: #F8F9FB;
  min-width: 400px;

  &__header {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 0 0 16px;
    margin-bottom: 32px;
    gap: 8px;
    height: 32px;
    border-left: 4px solid #4F4DD0;
    margin-left: 0;
    position: relative;

    p {
      font-size: 24px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.content-card {
  &__tag {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 61px;
    height: 24px;
    pointer-events: none;
    margin-bottom: 0;
  }

  &__body {
    padding: 0 32px 24px 32px;
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__title {
    width: 100%;
    height: 32px;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    color: #FFFFFF !important;
    cursor: pointer;
    margin-bottom: 0 !important;
  }
}

.website-type {
  position: absolute;
  width: 70px;
  height: 24px;
  right: 12px;
  top: 12px;
  background: #4F4DD0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
}

.news-block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;

  &__text {
    min-height: 56px;
    margin-bottom: 0;
    padding-bottom: 8px;
    cursor: pointer;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subheader {
    color: #8B919A;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  &__subheader-border {
    width: 16px;
    height: 0;
    border: 1px solid #C1C7CD;
    transform: rotate(90deg);
  }
}

.menu-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 0 0 !important;
  margin: auto;
  width: 100%;
  height: 57px;
  background: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  border-bottom: 1px solid #d8dce5;

  &__logo {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 12px;
    width: 185px;
    height: 40px;
    background: url(../images/logo.png) no-repeat;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 24px;
  }

  &__navigation {
    display: flex;
    gap: 4px;
    margin-right: auto;
  }

  .menu-item {
    font-size: .875rem;
    font-weight: 500;
    line-height: 22px;
    border-radius: 6px;
    color: #000 !important;
    text-decoration: none;
    cursor: pointer;
  }

  &__profile {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.fullscreen-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .spinner-border {
    color: #5E00B5 !important;
  }
}

.height-400 {
  height: 400px;
}

.height-500 {
  height: 500px;
}

.content-category {
  gap: 24px !important;
  background: #FFF;


  &__row {
    min-height: 512px;
    display: flex;
    flex-wrap: nowrap;
  }

  &__tag {
    position: absolute;
    width: 110px;
    height: 24px;
    right: 12px;
    top: 12px;
    background: #EBDEA0;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #343A3F;
  }

  &__title {
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    font-size: 20px;
    color: #000;
    background-color: rgba(248, 249, 251, 0.5);
    padding: 8px;
    border-radius: 4px;
  }
}

.content-category__row .occasions {
  padding: 32px 32px 24px;
  border-bottom: 1px solid #F0F2F5;

  &__id {
    display: flex;
    align-items: center;
    margin: 0 10px;
    filter: grayscale(25%);
    font-weight: 400;
    min-height: 26px;
  }

  &__skeleton {
    display: flex;
    align-items: center;
    height: 121px;
    margin: 0 10px;
    filter: grayscale(25%);
  }
}

.active-item{
  background-color: #d8dce5;
}

.offcanvas-body .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  --bs-nav-link-hover-color: #4F4DD0;
  font-weight: 600;
}

.map-container{
  position: relative;
  height: 400px;
  width: 100%;
}

.og-h3-list-button{
  border: 1px solid #5E00B5 !important;
  color: #5E00B5 !important;
  border-radius: 8px !important;
  background: var(--primary-500, #fff) !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
}


.og-h3-list-button-link{
  color: #5E00B5 !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  padding: 0 !important;
}

.og-h3-list-button:hover {
  background: var(--primary-400, #EEE8FF) !important;
}

.og-button-primary:hover {
  background: var(--primary-400, #8508E8) !important;
}


.login-page{
  &__wrapper{
    display: grid;
    position: relative;
    grid-template-rows: 1fr;
    grid-template-columns: 40% 60%;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    min-height: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__video-container{
    display: flex;
    position: relative;
    background-color: #f9fafc;
  }

  &__logo-container{
    position: absolute;
    bottom: 44px;
    left: 44px;
  }
}

.occasion-top{
  position: absolute;
  width: 75px;
  height: 24px;
  right: 12px;
  top: 12px;
  background: #EBDEA0;
  border-radius: 4px;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #343A3F;
  z-index: 10;
}

.skeleton-container{
  height: 100%;
  width: 100%;
}



.og-button-primary {
  display: inline-flex;
  padding: 9px 20px !important;
  border: 0 !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px !important;
  background: var(--primary-500, #5E00B5) !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;


  &:hover {
    background: var(--primary-400, #8508E8) !important;
  }

}

.spinner-overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 9999;
}

.spinner-border {
  color: #5E00B5 !important;
}

.p-avatar{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
}

.p-avatar-circle img{
  border-radius: 50%;
}

.p-avatar-image {
  background-color: transparent;
}

.p-avatar img{
  width: 100%;
  height: 100%;
}
